@import "./includes/settings.scss";

:root {
    font-size: 14px;
    font-family: "Montserrat";
    --text-primary: #b6b6b6;
    --text-secondary: #ececec;
    --bg-primary: #23232e;
    --bg-secondary: #141418;
    --transition-speed: 600ms;
}

body {
    color: $text-dark;
    background-color: white;
    margin: 0;
    padding: 0;

    div {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
    }

    pre {
        font-family: "Montserrat";
    }
    
    li,
    ul,
    ol,
    p,
    pre,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        padding: unset;
        margin: unset;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }

    main {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        margin-left: 5rem;
        flex: 1 1 100%;
    }

    .container {
        display: flex;
        flex: 1 1 100%;
    }

    .page-container {
        flex: 1 1 100%;
        align-content: flex-start;
        //background: linear-gradient(0.25turn, #000000, #3a3939, #3a3939, #3a3939, #000000);
        background: #000000;
        min-height: 100vh;

        // Phone
        @media (min-width: $phone) and (max-width: $tablet) {
            height: unset;
        }
    }
}

body::-webkit-scrollbar {
    width: 0.25rem;
}

body::-webkit-scrollbar-track {
    background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
    background: $primary;
}

.text-muted {
    color: #6c757d !important;
}


// Mobile display/remove elements
.mobile-show {
    display: none;
}
@media only screen and (max-device-width: 800px) {
    // Hide on mobile
    .mobile-hide {
        display: none;
    }
    // Show on mobile
    .mobile-show {
        display: flex;
    }
}

// 4k screen clamp
@media only screen and (min-device-width: 2100px) {
    .clamp {
        max-width: 1760px;
    }
    .flex-direction-container {
        flex-direction: column;
    }
}

// Button Styles
.btn-primary {
    background-color: transparent;
    font-family: "Montserrat";
    font-size: 1rem;
    font-weight: 500;
    border: 1px solid $text-light;
    border-radius: 10px;
    box-sizing: border-box;
    color: $text-dark;
    padding: 1rem 1.25rem;
    transition: background-color 0.5s ease-in-out, border-color 0.5s ease-in-out;

    
    &:hover {
        background-color: $primary;
        border-color: $primary;
        color: $text-light;
    }

    &:active {
        text-decoration: underline;
    }

    &:focus {
        text-decoration: underline;
        box-shadow: 0 0 0 0.1rem rgba($secondary, 0.5);
    }

    &:disabled {
        filter: grayscale(100%) opacity(0.7);
        background-color: $primary;
        border-color: $primary;
        color: $text-light;
        text-decoration: none;
        box-shadow: unset;
        opacity: 0.3;
        cursor: not-allowed;
    }
}

.btn-primary-alt {
    background-color: transparent;
    font-family: "Montserrat";
    font-size: 1rem;
    font-weight: 500;
    border: 1px solid $text-light;
    border-radius: 10px;
    box-sizing: border-box;
    color: $text-light;
    padding: 1rem 1.25rem;
    transition: background-color 0.5s ease-in-out, border-color 0.5s ease-in-out;

    
    &:hover {
        background-color: $primary;
        border-color: $primary;
    }

    &:active {
        text-decoration: underline;
    }

    &:focus {
        text-decoration: underline;
        box-shadow: 0 0 0 0.1rem rgba($secondary, 0.5);
    }

    &:disabled {
        filter: grayscale(100%) opacity(0.7);
        background-color: $primary;
        border-color: $primary;
        color: $text-light;
        text-decoration: none;
        box-shadow: unset;
        opacity: 0.3;
        cursor: not-allowed;
    }
}
.btn-primary.white {
    color: $text-dark;
    background-color: $text-light;

    &:hover {
        color: $text-light;
        background-color: $primary;
        border-color: $primary;
    }

    &:active {
        text-decoration: underline;
    }

    &:focus {
        text-decoration: underline;
        box-shadow: 0 0 0 0.1rem rgba($secondary, 0.5);
    }
    &:disabled {
        filter: grayscale(50%) opacity(0.7);
        background-color: $primary;
        border-color: $primary;
        color: $text-light;
        text-decoration: none;
        box-shadow: unset;
        opacity: 0.3;
        cursor: not-allowed;
    }
}

.btn-link {
    background-color: transparent;
    border: unset;
    color: $text-light;

    .link-text {
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 17px;
        font-weight: normal;
        font-style: normal;
        font-family: "Montserrat";

        &::after {
            content: "";
            width: 100%;
            height: 2px;
            margin-top: 0.5rem;
            display: block;
            background-color: $text-light;
            transition: all 0.5s ease;
        }
    }

    &:hover,
    &:focus {
        text-decoration: none;
        outline: none;
        .link-text {
            &::after {
                background-color: transparent;
            }
        }
    }
    &:disabled {
        filter: grayscale(50%) opacity(0.7);
        text-decoration: none;
        box-shadow: unset;
        opacity: 0.3;
        cursor: not-allowed;
    }
}

.btn-link.active {
    .link-text {
        &::after {
            background-color: $text-light;
        }
    }
}
