// Colors
$primary: #E5002B;
$secondary: #E5E5E5;
$text-light: white;
$text-dark: #000000;
$table-border: #DADADA;
$new-table-border: #cccaca;
$table-header-bg: #828282;
$timer-header: #333333;
$admin-badge: #b92332;
$background-color: #3a3939;

// Spacing
$paragraph-spacer: 3rem;
$block-spacer: 7rem;
$block-spacer-mobile: 5rem;
$paragraph-spacer-mobile: 3rem;

// Breakpoints
$phone: 320px;
$tablet: 768px;
$desktop: 1024px;
